import * as React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Page } from "@utdanningsdirektoratet/layout";
import { Notifications } from "@utdanningsdirektoratet/notifications";
import { SlowAction } from "@utdanningsdirektoratet/slowaction";
import { Placeholder } from "@utdanningsdirektoratet/placeholder";
import { useContextState } from "./StateProvider";
import AppContainer from "./layout/AppContainer";
import routes from "./routes";
import ErrorPage from "./pages/ErrorPage";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { LogonPage } from "./pages/LogonPage";
import { VelgAktør } from "./pages/VelgAktor/VelgAktør";
import { useCurrentOrganization, getCurrentUser } from "./utils/globalFunctions";
import { TilskuddsGrunnlag } from "./pages/Dashboard/components/Tilskudd/Tilskuddsgrunnlag/TilskuddsGrunnlag";
import { AltinnInfoLink } from "./pages/Dashboard/components/SharedComponents";
import { InfoPage } from "./pages/InfoPage";
import { UnauthorizedPage } from "./pages/UnauthorizedPage";
import { AvsluttedeSaker } from "./pages/AvsluttedeSaker";
import { HomeClient } from "./ApiClients";
import { AllTilskuddOverview } from "./pages/Dashboard/components/Tilskudd/AllTilskuddOverview";
import { LocalStorageKeys } from "./utils/localstorageKeys";
import { usePageViewTrackingWithAccessInfo } from "./utils/analyticsUtils";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

/** Dersom brukerinfo mangler, sender bruker til påloggingsside */
function RequireAuthentication({ children }: { children: JSX.Element }) {
  const user = getCurrentUser();
  if (user == null) return <Navigate to={routes.signInUidp} />;
  return children;
}

/** Sender saksbehandler/system administrator til aktørvelger dersom ingen aktør er valgt. Gir info ved manglende aktør. */
function RequireAktor({ children }: { children: JSX.Element }) {
  const user = getCurrentUser();
  const hasRoles = user && user.roller.length > 0;
  const currentOrganization = useCurrentOrganization();
  const [context, dispatch] = useContextState();
  React.useEffect(() => {
    dispatch({ type: "SET_ALTINN", payload: null });
    if (currentOrganization?.number == null) {
      return;
    }

    localStorage.setItem(LocalStorageKeys.CurrentOrganization, currentOrganization.number);

    const client = new HomeClient();
    const fn = async () => {
      const result = await client.getAltinnPermissions(currentOrganization.number);
      dispatch({ type: "SET_ALTINN", payload: { ...result, orgNr: currentOrganization.number } });
    };
    fn();
  }, [currentOrganization?.number, dispatch]);

  usePageViewTrackingWithAccessInfo()

  if (currentOrganization == null && hasRoles) return <Navigate to={routes.selectAktor} />;

  if (!hasRoles && user?.organizations.length === 0)
    return (
      <InfoPage>
        Vi kunne ikke finne noen virksomheter tilknyttet deg i Altinn. For å få tilgang til informasjon om en virksomhet i Tavla, må du være
        tilknyttet virksomheten i Altinn. Du kan finne mer informasjon om dette ved å gå til Udir sin{" "}
        <AltinnInfoLink>informasjonsside</AltinnInfoLink> om tilgangsstyring i Altinn.
      </InfoPage>
    );

  if (currentOrganization?.udirAktorId === null)
    return (
      <InfoPage>
        Vi kunne ikke finne noe informasjon om denne virksomheten. Har du valgt riktig virksomhet? For å få tilgang til informasjon om en virksomhet i
        Tavla, må du være tilknyttet virksomheten i Altinn. Du kan finne mer informasjon om dette ved å gå til Udir sin{" "}
        <AltinnInfoLink>informasjonsside</AltinnInfoLink> om tilgangsstyring i Altinn.
      </InfoPage>
    );

  // Rettigheter hentes så ikke vis resten ennå
  if (context.altinn == null || currentOrganization?.number !== context.altinn?.orgNr) return <Placeholder />;

  return children;
}

function RedirectToDashboardWithOrgNr() {
  const user = getCurrentUser();
  const hasRoles = user && user.roller.length > 0;

  if ((!hasRoles && user?.organizations.length === 0) || user == null)
    return (
      <InfoPage analyticsMessage="Ingen tilknyttede organisasjoner eller tilganger">
        Vi kunne ikke finne noen virksomheter tilknyttet deg i Altinn. For å få tilgang til informasjon om en virksomhet i Tavla, må du være
        tilknyttet virksomheten i Altinn. Du kan finne mer informasjon om dette ved å gå til Udir sin{" "}
        <AltinnInfoLink>informasjonsside</AltinnInfoLink> om tilgangsstyring i Altinn.
      </InfoPage>
    );

  if (hasRoles) {
    return <Navigate to={routes.selectAktor} />;
  }

  const currentOrganizationNumber = localStorage.getItem(LocalStorageKeys.CurrentOrganization);

  const organization =
    user.organizations.find((o) => o.number === currentOrganizationNumber) ??
    user.organizations.find((o) => o.udirAktorId != null) ??
    user.organizations[0];

  return <Navigate to={organization.number} replace />;
}

const App: React.FC = () => {
  const [state] = useContextState();

  if (state.error) {
    return <ErrorPage error={state.error} />;
  }

  return (
    <Router>
      <Page>
        <SlowAction />
        <SentryRoutes>
          <Route path={routes.home} element={<AppContainer />}>
            <Route path={routes.signInUidp} element={<LogonPage />} />
            <Route
              path={routes.selectAktor}
              element={
                <RequireAuthentication>
                  <VelgAktør />
                </RequireAuthentication>
              }
            />
            <Route
              path={routes.dashboardRedirectUrl(":orgnr")}
              element={
                <RequireAuthentication>
                  <RequireAktor>
                    <Dashboard />
                  </RequireAktor>
                </RequireAuthentication>
              }
            />
            <Route
              path={routes.dashboard(":orgnr", ":tab")}
              element={
                <RequireAuthentication>
                  <RequireAktor>
                    <Dashboard />
                  </RequireAktor>
                </RequireAuthentication>
              }
            />
            <Route
              path={routes.unauthorized(":orgnr")}
              element={
                <RequireAuthentication>
                  <UnauthorizedPage />
                </RequireAuthentication>
              }
            />
            <Route
              path={routes.avsluttedeSaker(":orgnr")}
              element={
                <RequireAuthentication>
                  <RequireAktor>
                    <AvsluttedeSaker />
                  </RequireAktor>
                </RequireAuthentication>
              }
            />
            <Route
              path={routes.tilskudd(":orgnr")}
              element={
                <RequireAuthentication>
                  <RequireAktor>
                    <AllTilskuddOverview />
                  </RequireAktor>
                </RequireAuthentication>
              }
            />
            <Route
              path={routes.grunnlag(":orgnr", ":budsjettAar", ":ordningId", ":isFromNewVertikal")}
              element={
                <RequireAuthentication>
                  <RequireAktor>
                    <TilskuddsGrunnlag />
                  </RequireAktor>
                </RequireAuthentication>
              }
            />
            <Route
              index
              element={
                <RequireAuthentication>
                  <RedirectToDashboardWithOrgNr />
                </RequireAuthentication>
              }
            />
          </Route>
        </SentryRoutes>
        <Notifications />
      </Page>
    </Router>
  );
};

export default App;
