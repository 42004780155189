/* eslint-disable comma-dangle */
import { TabTypes } from "./utils/globalFunctions";

export default {
  home: "/",
  // denne er for å sikre at om folk fortsatt når frem om de har bokmerket den gamle url-en for sin aktør
  dashboardRedirectUrl: (orgnr: string) => `/${orgnr}`,
  dashboard: (orgnr: string, tab: TabTypes | ":tab" = "Oversikt") => `/${orgnr}/${tab}`,
  unauthorized: (orgnr: string) => `/${orgnr}/unauthorized`,
  signOutUidp: "/signout",
  signInUidp: "/signin",
  selectAktor: "/aktors",
  grunnlag: (orgnr: string, budsjettAar: string, ordningId: string, isFromNewVertikal: string) =>
    `/${orgnr}/grunnlag/${budsjettAar}/${ordningId}/${isFromNewVertikal}`,
  avsluttedeSaker: (orgnr: string) => `/${orgnr}/avsluttede`,
  tilskudd: (orgnr: string) => `/${orgnr}/tilskuddsdetaljer`,
  udir: {
    home: "https://www.udir.no",
    kontakt: "https://www.udir.no/om-udir/kontakt-oss/",
    om: "https://www.udir.no/om-udir/",
  },
};
